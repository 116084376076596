@import url("https://fonts.googleapis.com/css?family=Lexend:100,200,300,400,500,700,900");
@import "~bootstrap/scss/bootstrap";

@import "./_colors";
@import "./_mixins";

@import "./error";

body {
  font-family: 'Lexend';
  position: relative;
}

html, body {
  margin: 0px;
  padding: 0px;
  overflow-x: clip;
}

a, input, button, textarea {
  &:active, &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.bg-blur {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.img-fluid {
  max-height: 100%;
  max-width: 100%;
}

.gradient-text {
  display: inline-block;
  background-color: #012AFF;
  background-image: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
  background-size: 100%;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.rounded-btn {
  cursor: pointer;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.1rem;
  height: 50px;
  width: fit-content;
  padding: 15px 20px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  i {
    margin-left: 10px;
  }

  &.outline-black {
    color: #000000;
    border: 1px solid #000000;

    &:hover {
      color: #FFFFFF;
      background: #000000;
    }
  }

  &.outline-primary {
    color: #012AFF;
    border: 1px solid #012AFF;

    &:hover {
      color: #FFFFFF;
      background: #012AFF;
    }
  }

  &.outline-white {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;

    &:hover {
      color: #000000;
      background: #FFFFFF;
    }
  }

  &.solid-white {
    color: #000000;
    background: #FFFFFF;

    &:hover {
      background: #E7E7E7;
    }
  }

  &.outline-solid-primary {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    background: #012AFF;

    &:hover {
      background: #011D62;
    }
  }

  &.gradient-primary {
    color: #FFFFFF;
    background-size: 100% 300%;
    background-image: linear-gradient(to top right, #011D62 0%, #012AFF 50%);
    background-position: 0 100%;

    &:hover {
      background-position: 0 0;
    }
  }
}

.toggle {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    input[type="checkbox"] {
      display: block;
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:checked + .toggle-btn:after {
        left: 26px;
      }
    }

    .toggle-btn {
      display: inline-block;
      position: relative;
      height: 30px;
      width: 55px;
      background-color: #012AFF;
      border-radius: 30px;
      padding: 15px 0 15px 4px;
      cursor: pointer;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 1px;
        height: 28px;
        width: 28px;
        background: #FFF;
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        transform: translateY(-50%);
        transition: left 0.2s ease-in;
      }
    }
}

nav.main-nav.is-pinned + #toast {
  top: 125px;
}

#toast {
  position: fixed;
  top: 170px;
  right: 30px;
  z-index: 999;
  transition: top 0.2s ease-in-out;
  
  .slvt-toast {
    border-radius: 12px;
    background-color: rgba(255,255,255,.8);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    padding: 20px 35px 20px 25px;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 10px;
    max-width: 400px;
    transform: translateX(calc(100% + 30px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
  
    &.slvt-toast-info {
      .slvt-toast-icon {
        background-color: #03A9F4;
        &:before {
          content: "\f129";
        }
      }
    }
  
    &.slvt-toast-success {
      .slvt-toast-icon {
        background-color: #4CAF50;
        &:before {
          content: "\f00c";
        }
      }
    }
  
    &.slvt-toast-error {
      .slvt-toast-icon {
        background-color: #FF0000;
        &:before {
          content: "\f00d";
        }
      }
    }
  
    &.slvt-toast-warn {
      .slvt-toast-icon {
        background-color: #FFC107;
        &:before {
          content: "\f12a";
        }
      }
    }
  
    &.active {
      transform: translateX(0%);
    }
  
    .slvt-toast-content {
      display: flex;
      align-items: center;
  
      .slvt-toast-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        min-width: 35px;
        color: #fff;
        font-size: 20px;
        border-radius: 50%;
  
        &:before {
          font-family: "Font Awesome 6 Sharp";
        }
      }
  
      .slvt-toast-message {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
  
        .slvt-toast-message-status {
          font-weight: 400;
          font-size: 1.1rem;
        }
  
        .slvt-toast-message-text {
          font-weight: 300;
        }
      }
    }
  
    .slvt-toast-close-btn {
      position: absolute;
      top: 10px;
      right: 15px;
      padding: 5px;
      cursor: pointer;
      opacity: 0.7;
  
      &:hover {
        opacity: 1;
      }
    }
  
    .slvt-toast-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
  
      @keyframes progress {
        100% {
          right: 100%;
        }
      }
  
      &.active:before {
        animation: progress 7s linear forwards;
      }
  
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: #2770ff;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  nav.main-nav.is-pinned + #toast {
    top: 90px;
  }
  
  #toast {
    right: 10px;

    .slvt-toast {
      width: calc(100vw - 20px);
    }
  }
}

#cookie-notice {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 30px;
  color: #FFFFFF;
  z-index: 999;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  background-color: #000000;
  background-color: rgba(0,0,0,.8);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);

  .cookie-notice-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .cookie-notice-text {
    font-weight: 200;
    margin-bottom: 20px;

    a {
      color: #FFFFFF;
    }
  }

  .cookie-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 1.2rem;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .rounded-btn {
    display: inline-block;
    margin-right: 10px;
  }
}

@include media-breakpoint-down(sm) {
  #cookie-notice{
    .cookie-notice-text {
      padding-right: 10px;
    }
  }
}

#contact-modal {
  position: fixed;
  top: 170px;
  left: 50%;
  z-index: 998;
  opacity: 0;
  width: 1020px;
  max-width: calc(100vw - 24px);
  height: 525px;
  min-height: 525px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,.8);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  -webkit-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transform: translateX(-50%) scale(0.9);
  transform: translateX(-50%) scale(0.9);
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &.show {
    -webkit-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
    opacity: 1;
    pointer-events: unset;
  }

  .modal-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: rgba(1, 42, 255, 0.3);
    
    .modal-progress-bar {
      height: 100%;
      width: 0;
      background-color: #011D62;
      background: linear-gradient(to right, #011D62 0%, #012AFF 100%);
      -webkit-transition: width 0.5s ease-out;
      transition: width 0.5s ease-out;
    }
  }

  .modal-close,
  .modal-prev,
  .modal-next {
    cursor: pointer;
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.8rem;
    color: #747474;

    &:hover, &:active {
      color: #000000;
    }
  }

  .modal-next,
  .modal-prev {
    position: absolute;
    top: 50%;
    font-size: 1.8rem;
    color: #747474;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    &[disabled="true"] {
      color: #CCCCCC;
      cursor: unset;
    }

    &:hover {
      &:not([disabled="true"]) {
        color: #000000;
      }
    }
  }

  .modal-next {
    right: 20px;
  }

  .modal-prev {
    left: 20px;
  }

  .modal-content-slides {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 60px;
    height: 100%;
    width: 100%;

    .modal-slides {
      position: relative;
      width: 100%;

      .modal-slide {
        position: absolute;
        width: 100%;

        &.hidden-bottom {
          opacity: 0;
          transform: translateY(100vh);
          pointer-events: none;
          -webkit-transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;
          transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;
        }

        &.in-view {
          opacity: 1;
          transform: translateY(0%);
          -webkit-transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;
          transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;
        }

        &.hidden-top {
          opacity: 0;
          transform: translateY(-100vh);
          pointer-events: none;
          -webkit-transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;
          transition: opacity 1s ease-in-out, transform 0.3s ease-in-out;
        }

        .modal-question {
          display: flex;
          font-weight: 300;
          font-size: 2rem;
          margin-top: 30px;
        
          .modal-question-num {
            flex: 0 0 auto;
            color: #012AFF;
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 1.6rem;
            width: 60px;
            margin-top: 12px;

            i {
              margin-left: 10px;
            }
          }

          .modal-question-text {
            flex: 1;
          }
        }

        .modal-answer {
          margin: 60px 60px;

          input {
            width: 100%;
            background: unset;
            border: none;
            border-bottom: 1px solid #012AFF;
            border-radius: 0;
            padding: 10px 0;
            margin-bottom: 20px;
            font-size: 1.4rem;
            font-weight: 100;
          }

          select {
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            background: unset;
            border: none;
            border-bottom: 1px solid #012AFF;
            border-radius: 0;
            padding: 10px 0;
            margin-bottom: 20px;
            font-size: 1.4rem;
            font-weight: 100;
            cursor: pointer;

            &:invalid {
              color: #747474;
            }
          }

          .modal-actions {
            display: flex;
            align-items: center;

            .modal-action-helper {
              margin-left: 20px;
              font-size: 0.8rem;
              font-weight: 100;
              color: #747474;

              span {
                font-weight: 500;
              }

              i {
                margin-left: 5px;
              }
            }
          }

          .error-message {
            margin-top: 15px;
            color: #FF0000;
            font-weight: 300;

            i {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .modal-privacy-notice {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 60px 60px;
    font-weight: 200;
    font-size: 0.8rem;
    color: #747474;

    a {
      font-weight: 400;
      color: #747474;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #contact-modal {
    top: 100px;
    height: unset;
    min-height: 375px;

    .modal-close,
    .modal-prev,
    .modal-next {
      i {
        font-size: 1.4rem;
      }
    }

    .modal-close {
      top: 10px;
    }

    .modal-prev {
      top: 30px;
    }

    .modal-next {
      left: 60px;
      top: 30px;
      right: unset;
    }

    .modal-content-slides {
      padding: 60px 20px 20px;

      .modal-slides {
        .modal-slide {
          .modal-question {
            margin-top: 0px;
            font-size: 1.4rem;
    
            .modal-question-num {
              margin-top: 0;
              line-height: unset;
              font-size: 1.4rem;
            }
          }

          .modal-answer {
            margin: 10px 0px;
          
            input {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .modal-privacy-notice {
      bottom: 0;
      left: 0;
      padding: 0 20px 20px;
    }
  }
}

.section-title,
.section-sub-title {
  font-weight: 500;
  position: relative;
  display: block;
  width: fit-content;
  margin: 0 auto 4rem;
}

.section-title {
  font-size: 2.8rem;

  .accent {
    position: absolute;
    bottom: -2rem;
    display: block;
    height: 3px;
    width: 50px;
    background-color: #011D62;
    background: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
  
    &.accent-left {
      left: 0;
    }
  
    &.accent-center {
      left: 50%;
      margin-left: -25px;
    }
  }
}

.section-sub-title {
  margin-bottom: 2rem;
}

nav.top-nav {
  background: #000000;
  color: #FFFFFF;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 400;
  width: 100%;

  a {
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .phone-number,
  .support-links {
    display: flex;
    align-items: center;
    height: 45px;
  }

  .phone-number {
    i {
      margin-right: 5px;
    }
  }

  .support-links {
    justify-content: right;

    i {
      margin-left: 5px;
    }

    .separator {
      font-weight: 200;
      margin: 0 10px;
      margin-top: -3px;
    }
  }
}

nav.main-nav {
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,.8);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  width:100%;
  z-index: 1020;

  .navbar-nav {
    align-items: center;
    justify-content: center;
  }

  .navbar-toggler {
    border: none;
    background: transparent !important;
    padding: 0;

    &:focus, 
    &:active {
      outline: 0;
      text-decoration: none;
      box-shadow: none;
    }

    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      margin-top: 4px;
      margin-bottom: 4px;
      transition: all 0.2s;
      background-color: #000000;
    }

    &[aria-expanded="true"] {
      .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
  
      .middle-bar {
        opacity: 0;
      }
  
      .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }


    .top-bar {
      transform: rotate(0);
    }

    .middle-bar {
      opacity: 1;
    }

    .bottom-bar {
      transform: rotate(0);
    }
  }

  .nav-item {
    .nav-link {
      color: #000000;
      font-size: 1.1rem;
      font-weight: 400;

      i {
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
      }

      &.show[aria-expanded="true"] {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .dropdown-menu {
    background: #000000;
    border-radius: 20px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    padding: 1.2rem 0;

    .dropdown-item {
      color: #FFFFFF;
      font-weight: 300;
      padding: .5rem 1.6rem;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: #FFFFFF;
        background-color: unset;
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  nav.main-nav {
    .dropdown-menu {
      top: 80px;
    }
  }
}

@include media-breakpoint-down(lg) {
  nav.main-nav {
    position: fixed;

    &.navbar-expanded {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .navbar-brand {
      img {
        height: 55px;
      }
    }

    .navbar-nav {
      height: calc(100vh - 81px);
      justify-content: center;
      overflow: scroll;

      .nav-link {
        font-weight: 400;
        font-size: 2rem;
      }
    }

    .nav-item {
      text-align: center;
      width: 100%;
    }

    .dropdown-menu {
      .dropdown-item {
        text-align: center;
      }
    }

    .contact-us-nav-btn {
      margin-top: 2rem;
    }
  }
}

#home {
  #hero {
    position: relative;
    margin-top: -96px;
    height: 650px;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: #CCCCCC;
    color: #FFFFFF;

    .hero-image {
      position: absolute;
      left: 0;
      background-size: cover;
      background-position: 25% 40%;
      width: 100%;
      height: 100%;
      transform: scale(1.05);
      -webkit-transition: background-image 0.5s ease-in-out, 0.2s filter ease-out;
      transition: background-image 0.5s ease-in-out, 0.2s filter ease-out;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .hero-content-wrapper {
      position: relative;

      .hero-content-inner {
        align-items: center;

        .hero-content {
          h1 {
            font-size: 3rem;
            font-weight: 700;
            text-shadow: 0 0 5px rgba(0,0,0,0.3);
            margin-top: 2rem;
            margin-left: 0;
            margin-right: 0;
          }
    
          h4 {
            font-size: 1.75rem;
            font-weight: 300;
            text-shadow: 0 0 5px rgba(0,0,0,0.3);
            margin-bottom: 3rem;
          }
        }
      }
    }

  }

  @include media-breakpoint-down(lg) {
    #hero {
      margin-top: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    #hero {
      .hero-content-wrapper {
        .hero-content-inner {
          .hero-content {
            h1 {
              font-size: 2.5rem;
            }
    
            h4 {
              font-size: 1.7rem;
            }
          }
        }
      }
    }
  }

  #about-us {
    margin: 6rem 0;

    .about-left {
      position: relative;

      .about-image-container {
        position: relative;
  
        .pluses {
          position: absolute;
          z-index: -2;
          transition: transform 0.3s ease-out;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
  
        .circle {
          position: absolute;
          top: 50px;
          right: 80px;
          z-index: -1;
          display: block;
          height: 400px;
          width: 400px;
          border-radius: 50%;
          background-color: #011D62;
          background: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
          transition: transform 0.3s ease-out;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
  
        .about-image-inner {
          position: absolute;
          top: 45px;
          left: 45px;
          height: 400px;
          width: 575px;
          border-radius: 30px;
          overflow: hidden;
          box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
          background-color: #CCCCCC;
  
          .about-image {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom right -50px;
            transform: scale(1.05);
            -webkit-transition: background-image 0.5s ease-in-out, 0.2s filter ease-out;
            transition: background-image 0.5s ease-in-out, 0.2s filter ease-out;
          }

          .circuits {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .circuit-top {
              position: absolute;
              top: 10px;
              left: 0px;
            }
    
            .circuit-bottom {
              position: absolute;
              bottom: 15px;
              right: 0px;
            }
          }

        }
      }
  
      .discover {
        position: absolute;
        bottom: 40px;
        width: 80%;
      }
    }

    h1 {
      font-weight: 500;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      margin-top: 30px;

      b {
        font-weight: 700;
      }
    }

    .rounded-btn {
      margin: 2rem 0 2.25rem;
    }
  }

  @include media-breakpoint-down(xl) {
    #about-us {
      .about-left {
        .about-image-container {
          top: 100px;

          .circle {
            right: 20px;
            top: 0px;
          }

          .about-image-inner {
            max-width: 100%;
            width: 450px;
            height: 350px;

            .circuits {
              .circuit-top {
                left: -50px;
              }
  
              .circuit-bottom {
                right: -50px;
              }
            }
          }
        }

        .discover {
          bottom: 200px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    #about-us {
      .about-left {
        position: unset;
        text-align: center;

        .about-image-container {
          top: 0;

          .about-image-inner {
            position: unset;
            margin: 0 auto;
          }
        }

        .discover {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  #our-services {
    padding: 3rem 0;
    position: relative;

    .logo-xl {
      position: absolute;
      left: -375px;
      top: -96px;
      background-repeat: no-repeat;
      height: 900px;
      width: 1200px;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.5s ease-in-out, transform 0.3s ease-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .service-card {
      cursor: pointer;
      position: relative;
      height: 480px;
      width: 350px;
      overflow: hidden;
      border-radius: 30px;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
      margin: 0 auto 5rem;
      background-color: #CCCCCC;

      .service-image {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(1.05);
        -webkit-transition: background-image 0.5s ease-in-out, 0.2s filter ease-out;
        transition: background-image 0.5s ease-in-out, 0.2s filter ease-out;
      }

      .service-content {
        position: absolute;
        top: 365px;
        height: 100%;
        width: 100%;
        color: #FFFFFF;
        text-align: center;
        background: linear-gradient(to top right, rgba(1,29,98,.8) 0%, rgba(1,42,255,.8) 100%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-transition: top 0.3s ease-in-out;
        transition: top 0.3s ease-in-out;

        .service-content-inner {
          position: relative;
          height: 100%;
          width: 100%;
          padding: 1.5rem;
          -webkit-transition: padding-top 0.3s ease-in-out;
          transition: padding-top 0.3s ease-in-out;

          .service-title {
            display: flex;
            min-height: 68px;
            margin-bottom: 3rem;
            align-items: center;
            justify-content: center;
          }

          .service-text {
            font-weight: 200;
          }

          .service-nav-icon {
            position: absolute;
            right: 20px;
            bottom: 20px;
            transform: rotate(-30deg);
          }
        }
      }

      &:hover {
        .service-content {
          top: 0;

          .service-content-inner {
            padding-top: 4rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    #our-services {
      .service-card {
        margin-bottom: 3rem;
      }
    }
  }

  #marquee {
    font-size: 6rem;
    font-weight: 900;
    letter-spacing: 2px;
    white-space: nowrap;
    position: relative;
    height: 250px;

    .marquee-1 {
      position: absolute;
      top: 0;
      left: -4000px;
      transition: transform 0.3s ease-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .marquee-2 {
      position: absolute;
      top: 90px;
      right: -4000px;
      transition: transform 0.3s ease-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }

  #our-process {
    padding: 3rem 0;

    .process-step {
      position: relative;

      .process-num {
        border: 2px solid #0127D4;
        border-radius: 90px;
        color: #0127D4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 200;
        font-size: 64px;
        line-height: 64px;
        height: 135px;
        width: 135px;
        margin: 20px auto;
      }

      p {
        font-weight: 300;
      }

      &::before {
        position: absolute;
        top: -100px;
        left: -20px;
        color: #F2F2F2;
        font-size: 25rem;
        line-height: 25rem;
        font-weight: 700;
        z-index: -1;
      }

      &.step-1::before {
        content: '1';
      }

      &.step-2::before {
        content: '2';
      }

      &.step-3::before {
        content: '3';
      }
    }
  }

  @include media-breakpoint-down(lg) {
    #our-process {
      .process-step {
        max-width: 400px;

        &::before {
          top: -80px;
          left: 50px;
          font-size: 20rem;
          line-height: 20rem;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    #our-process {
      .process-step {
        max-width: 250px;

        &::before {
          top: -80px;
          left: -20px;
        }
      }
    }
  }

  #our-plans {
    .plan-type {
      display: flex;
      justify-content: center;
      padding-left: 5px;

      .monthly {
        padding-left: 5px;
      }

      span {
        color: #979797;
        font-size: 18px;
        font-weight: 400;
        margin: 0 30px;

        &.active {
          color: #000000;
        }
      }
    }

    .annual-notice {
      margin: 30px 0;
      color: #D5D5D5;
      font-weight: 200;
      font-size: 1rem;
    }

    .package-plan-card {
      min-height: 770px;
      min-width: 300px;
      max-width: 350px;
      border-radius: 30px;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
      margin: 0 auto 20px;
      padding: 40px 20px;
      text-align: center;

      &.light {
        background-color: #FFFFFF;
        color: #000000;

        .plan-billing, hr {
          color: #D5D5D5;
        }

        hr {
          color: #D5D5D5;
        }
      }

      &.dark {
        background-color: #011D62;
        background: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
        color: #FFFFFF;

        .plan-billing, hr {
          color: #5077FF;
        }
      }

      .package-plan-main {
        .plan-name {
          font-size: 2rem;
          font-weight: 400;
          line-height: 2rem;
        }
        
        .plan-price-container {
          padding: 30px 0;

          .plan-price {
            font-size: 1.5rem;
            font-weight: 500;

            span {
              font-size: 4rem;
              font-weight: 400;
              line-height: 3.5rem;
            }
          }

          .plan-structure {
            font-size: 1.25rem;

            .plan-billing {
              font-size: 1rem;
              font-weight: 200;
            }
          }
        }

        .plan-text {
          display: flex;
          align-items: center;
          justify-self: center;
          padding: 30px;
          height: 175px;
          font-size: 1.5rem;
          font-weight: 300;
        }
      }

      .package-plan-content {
        padding-top: 30px;

        ul {
          list-style: none;
          font-weight: 200;
          font-size: 1rem;
          margin-bottom: 0px;
          padding: 0 15px;

          li {
            margin: 10px 0;

            &:first-child {
              margin: 0;
            }
          }
        }

        p {
          padding: 30px 15px;
          font-weight: 200;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
  }

  #our-partners {
    margin: 3rem 0 6rem;
    position: relative;

    .logo-xl {
      position: absolute;
      right: -375px;
      top: -48px;
      background-repeat: no-repeat;
      height: 900px;
      width: 1200px;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.5s ease-in-out, transform 0.3s ease-out;
    }
    
    .partner-text {
      .highlight {
        display: inline-block;
        font-weight: 700;
        background-color: #012AFF;
        background-image: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
        background-size: 100%;
        -moz-background-clip: text;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
      }
    }

    .partner-logo {
      background: #FFFFFF;
      // border: 1px solid #F4F4F4;
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
      border-radius: 30px;
      height: 140px;
      width: 200px;
      margin: 20px 0;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include media-breakpoint-down(sm) {
    #our-partners {
      .partner-logo {
        height: 105px;
        width: 150px;
        margin: 10px 0;
        border-radius: 20px;
      }
    }
  }

  #get-started-cta {  
    .get-started {
      color: #FFFFFF;
      position: relative;
      background-color: #012AFF;
      background: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      z-index: 0;
      margin-bottom: -150px;

      .circuit {
        background-repeat: no-repeat;
        background-position: top 25px center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.1;
        z-index: -1;
      }

      h2 {
        margin: 2rem 0;
      }

      h4 {
        margin: 2rem 0;
        font-weight: 300;
      }

      .rounded-btn {
        margin: 2rem 0 2.5rem;
      }
    }
  }
}

#privacy {
  #policy {
    position: relative;
    margin-top: 75px;
    margin-bottom: -150px;
    z-index: 10;

    .policy-container {
      background: #FFFFFF;
      border-radius: 30px;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      
      .policy-header {
        text-align: center;
        position: relative;
        color: #FFFFFF;
        background-color: #012AFF;
        background: linear-gradient(to top right, #011D62 0%, #012AFF 100%);
        min-height: 280px;

        .circuit {
          background-repeat: no-repeat;
          background-position: top 25px center;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0.1;
          z-index: 1;
        }

        .policy-header-content {
          height: 100%;
          width: 100%;
          margin: auto;

          .policy-title {
            display: block;
            font-weight: 300;
          }

          .policy-date {
            font-size: 1rem;
            font-weight: 100;
            color: #dddddd;
          }
        }
      }

      .policy-body {
        padding: 75px;

        .policy-body-content {
          h5 {
            font-weight: 400;
            margin-bottom: 20px;
          }

          p, ul > li {
            font-weight: 200;
            color: #777777;
          }

          ul {
            padding-left: 20px;

            li {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    #policy {
      margin-top: 96px;

      .policy-container {
        .policy-body {
          padding: 30px;
        }
      }
    }
  }

}

footer {
  background-color: #F2F2F2;
  z-index: -3;

  .footer-top {
    padding: 18rem 0 7rem;

    .footer-links {
      ul {
        font-weight: 300;
        list-style-type: none;
        padding-left: 0;

        li {
          margin: 1rem 0;

          a {
            color: #000000;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .newsletter {
      margin-bottom: 2rem;

      p {
        color: #747474;
        font-weight: 300;
        margin-top: 1rem;
      }

      #newsletter-form {
        input[type="email"] {
          border: 1px solid #E0E0E0;
          border-radius: 50px;
          font-size: .9rem;
          font-weight: 200;
          height: 50px;
          padding: 1rem 4rem 1rem 1rem;
          margin: 0 -5px;
          width: 250px;
          vertical-align: middle;
  
          &::placeholder {
            color: #C7C7C7;
          }
        }

        .submit-btn {
          background: #012AFF;
          border: none;
          border-radius: 30px;
          color: #FFFFFF;
          height: 50px;
          width: 50px;
          vertical-align: middle;
          margin-left: -50px;
          padding: 0;
        }
      }
    }

    .social {
      a {
        color: #D5D5D5;
        margin-right: 2rem;
        -webkit-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #747474;
        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid #E2E2E2;
    color: #747474;
    font-size: .9rem;
    font-weight: 300;
    padding: 2rem 0;

    .privacy-terms {
      a {
        color: #747474;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .terms {
        margin-left: 1rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  footer {
    #newsletter-form {
      input[type="email"] {
        width: 90% !important;
      }
    }
  }
}