#error {
  color: #FFFFFF;
  background: #012AFF;

  .error-image {
    margin: 100px 0 50px;
  }

  .error-message {
    line-height: 2.75rem;
    font-size: 2.2rem;
    font-weight: 100;
  }

  .error-detail-container {
    font-weight: 100;
    margin: 100px 0;
    overflow: auto;

    .error-qr-code {
      width: 200px;
      float: left;
    }

    .error-detail {
      float: left;
      padding-left: 20px;
      max-width: calc(100% - 200px);

      .report-error {
        a {
          word-wrap: break-word;
          color: #FFFFFF;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .error-code-info {
        margin-top: 20px;
      }
    }
  }
}